<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Dashboard
      </div>

      <div class="card">
        <div class="mb-3">
          <Dropdown class="bg-secondary font-weight-bolder" v-model="dateSelected" :options="reportDates"
            optionLabel="text" optionValue="value" dataKey="value" @change="load" />
        </div>
        <div class="mb-3">
          <div v-if="dateSelected === 'custom'" class="flex flex-column mb-2 md:justify-content-start md:flex-row md:align-items-center">
            <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
              <Calendar v-model="dateStartFormated" @update:v-model="startDate" :showIcon="true" placeholder="Data inicial" @date-select="load" />
            </div>
            <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 ml-4">
              <Calendar v-model="dateFinishFormated" @update:v-model="finishDate" :showIcon="true" placeholder="Data final" @date-select="load" />
            </div>
          </div>
          <div class="col">
            <div>
              <small class="text-muted">
                Dados do dia {{ $filters.date(startDate) }}
                até dia {{ $filters.date(finishDate) }}
              </small>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-3" v-for="(item, idx) in resultado" :key="idx">
            <router-link :to="`/reports/${item.type}?startDate=${startDate}&finishDate=${finishDate}`">
              <div class="card bg-primary">
                <div class="card-body pb-0">
                  <h4 class="mb-0">{{ item.value }}</h4>
                  <p>{{ item.detail }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CrudService from '../../services/crud'

export default {
  // components: {
  //   VueFunnelGraph
  // },
  data() {
    return {
      startDate: null,
      finishDate: null,
      dateSelected: 'atual',
      records: [],
      filters: {
        dateStart: null,
        dateFinish: null
      },
      dateStartFormated: null,
      dateFinishFormated: null
    }
  },
  computed: {
    start() {
      if (this.dateSelected === 'atual') {
        return moment().startOf('month').format()
      }
      if (this.dateSelected === 'anterior') {
        return moment().add(-1, 'month').startOf('month').format()
      }

      if (this.dateSelected === 'custom') {
        return 'custom'
      }

      return moment().add(-2, 'month').startOf('month').format()
    },
    finish() {
      if (this.dateSelected === 'anterior') {
        return moment().add(-1, 'month').endOf('month').format()
      }
      return moment().endOf('month').format()
    },
    reportDates() {
      return [
        { value: 'atual', text: 'Mês atual' },
        { value: 'anterior', text: 'Mês anterior' },
        { value: 'meses', text: 'Últimos 3 meses' },
        { value: 'custom', text: 'Personalizado' }
      ]
    },
    colors() {
      return ['#FFB178', '#FF3C8E', '#A0BBFF']
    },
    labels() {
      return this.resultado.map(item => item.title)
    },
    values() {
      return this.resultado.map(item => item.value)
    },
    resultado() {
      const self = this
      const labels = [
        { type: 'search', title: 'Consultas', class: 'bg-primary', detail: 'Consultas ao CPF' },
        { type: 'confirm', title: 'Confirmações', class: 'bg-info', detail: 'Confirmações de cadastro' },
        { type: 'proposal', title: 'Propostas', class: 'bg-warning', detail: 'Consulta a propostas' },
        { type: 'agreement', title: 'Acordos', class: 'bg-danger', detail: 'Acordos fechados' },
      ]
      return labels.map(item => {
        const val = self.records.find(i => i.type === item.type)
        return {
          ...item,
          value: (val && val.total) || 0
        }
      })
    }
  }
  ,
  methods: {
    async load() {
      if (this.dateSelected === 'custom') {
        if (!this.dateStartFormated && !this.dateFinishFormated) {
          return
        }

        if (this.dateStartFormated) {
          this.startDate = moment(this.dateStartFormated, 'DD/MM/YYYY').startOf('day').format();
          this.dateStartFormated = moment(this.startDate).format('DD/MM/YYYY');
        }

        if (this.dateFinishFormated) {
          this.finishDate = moment(this.dateFinishFormated, 'DD/MM/YYYY').endOf('day').format();
          this.dateFinishFormated = moment(this.finishDate).format('DD/MM/YYYY');
        }
      } else {
        this.startDate = this.start;
        this.finishDate = this.finish;
      }

      const service = new CrudService('/admin/relatorios');
      const { data } = await service.findAll({ dateStart: this.startDate, dateFinish: this.finishDate });
      this.records = data.data
      this.startDate = data.start
      this.finishDate = data.finish
    }
  },
  mounted() {
    this.load();
  }
}
</script>
